import { useState, useEffect } from 'react';

function useDynamicWidth(value, placeholder, minWidth = 100) {
    const [width, setWidth] = useState(minWidth);

    useEffect(() => {
        const content = value || placeholder;
        const span = document.createElement('span');
        span.textContent = content;
        span.style.font = 'inherit';
        span.style.visibility = 'hidden';
        span.style.position = 'absolute';
        span.style.whiteSpace = 'nowrap';
        document.body.appendChild(span);
    
        const textWidth = Math.max(span.offsetWidth + 80, minWidth);
    
        if (textWidth !== width) {
            setWidth(textWidth);
        }
    
        document.body.removeChild(span);
    
        // Clean-up function
        return () => {
            if (span.parentNode) {
                span.parentNode.removeChild(span);
            }
        };
    }, [value, placeholder, minWidth, width]); // Added 'width' to dependency array
    
    return width;
}

export default useDynamicWidth;

import React, { useState, useEffect } from 'react';
import useDynamicWidth from '../hooks/useDynamicWidth';
import '../assets/styles/contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import emailjs from 'emailjs-com';

const welcomeTexts = [
    "Let's get the ball rolling.", "Ready to start a conversation?", "Reach out and let's collaborate!",
    "Welcome to my contact page.", "Looking forward to hearing from you.", "Get in touch and let's create something amazing."
];
const subtitleTexts = [
    "I'm excited to hear from you.", "Your message is just a click away.", "Let's start a conversation.",
    "I'm all ears.", "I'm here to help.", "Can't wait for your message.", "Reach out to me."
];

function ContactPage() {
    const [name, setName] = useState('');
    const [service, setService] = useState('');
    const [email, setEmail] = useState('');
    const [setErrors] = useState({});

    // State to hold stable random placeholders
    const [placeholderName, setPlaceholderName] = useState('');
    const [placeholderService, setPlaceholderService] = useState('');
    const [welcomeText, setWelcomeText] = useState('');
    const [subtitleText, setSubtitleText] = useState('');

    // Effect to set placeholders and random texts only once on mount
    useEffect(() => {
        // random name placeholder
        const firstNames = ['John', 'Jane', 'Michael', 'Emily', 'David', 'Sarah'];
        const lastNames = ['Smith', 'Jordan', 'Williams', 'Brown', 'Jones', 'Miller'];
        const getRandomName = () => {
            const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
            const randomLastName = lastNames[Math.floor(Math.random() * lastNames.length)];
            return `${randomFirstName} ${randomLastName}`;
        };

        const services = ['web development', 'data analytics', 'fullstack development', 'mobile app development', 'AI/ML development', 'an offer you can\'t refuse'];
        const getRandomService = () => {
            const randomService = services[Math.floor(Math.random() * services.length)];
            return randomService;
        };

        setPlaceholderName(getRandomName());
        setPlaceholderService(getRandomService());
        setWelcomeText(welcomeTexts[Math.floor(Math.random() * welcomeTexts.length)]);
        setSubtitleText(subtitleTexts[Math.floor(Math.random() * subtitleTexts.length)]);
    }, []);

    const nameWidth = useDynamicWidth(name, placeholderName);
    const serviceWidth = useDynamicWidth(service, placeholderService);
    const emailWidth = useDynamicWidth(email, "your email address");

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validateName = (name) => {
        return name.trim() !== '';
    };

    const validateService = (service) => {
        return service.trim() !== '';
    };

    const handleValidation = () => {
        const newErrors = {};
        if (!validateEmail(email)) {
            newErrors.email = 'Please enter a valid email address.';
        }
        if (!validateName(name)) {
            newErrors.name = 'Name cannot be empty.';
        }
        if (!validateService(service)) {
            newErrors.service = 'Service cannot be empty.';
        }
        return newErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formErrors = handleValidation();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            alert('Please fix the following errors:\n' + Object.values(formErrors).join('\n'));
            return; // Stop the form from submitting
        }

        // Prepare the email parameters
        const emailParams = {
            from_name: name,
            to_name: "Chong Yong",
            message: `Service Requested: ${service}. Contact email: ${email}.`
        };

        emailjs.send('service_yq9f286', 'template_0uu65io', emailParams, '1jV7Dh5p3zfN1roP1')
            .then((result) => {
                console.log(result.text);
                alert('Your message has been sent! I will get back to you as soon as possible.');
            }, (error) => {
                alert('An error occurred, please try again later. Or you can reach me at my socials.');
                console.log(error.text);
            });

        // Clear the form or handle other post-submit actions
        setName('');
        setService('');
        setEmail('');
    };

    return (
        <div className="contact-page">
            <div className="contact-left">
                <h1> {welcomeText} </h1>
                <p className="subtitle">{subtitleText}</p>
                <div className="icons">
                    <p>links:</p>
                    <a href="https://github.com/ceewaigithub" className="fa fa-github">
                        <FontAwesomeIcon icon={faGithub} />
                    </a>
                    <a href="https://leetcode.com/u/CeeWai/" className="fa fa-leetcode">
                        <svg className="svg-leetcode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{ height: '1.5rem' }}>
                            <path fill="currentColor" d="m21.469 23.907l-3.595 3.473c-.624.625-1.484.885-2.432.885s-1.807-.26-2.432-.885l-5.776-5.812c-.62-.625-.937-1.537-.937-2.485c0-.952.317-1.812.937-2.432l5.76-5.844c.62-.619 1.5-.859 2.448-.859s1.808.26 2.432.885l3.595 3.473c.687.688 1.823.663 2.536-.052c.708-.713.735-1.848.047-2.536l-3.473-3.511a6.793 6.793 0 0 0-3.261-1.787l3.287-3.333c.688-.687.667-1.823-.047-2.536s-1.849-.735-2.536-.052L4.553 13.968c-1.307 1.312-1.989 3.113-1.989 5.113c0 1.996.683 3.86 1.989 5.168l5.797 5.812c1.307 1.307 3.115 1.937 5.115 1.937c1.995 0 3.801-.683 5.109-1.989l3.479-3.521c.688-.683.661-1.817-.052-2.531s-1.849-.74-2.531-.052zm6.28-6.558H14.218c-.932 0-1.692.801-1.692 1.791c0 .991.76 1.797 1.692 1.797h13.531c.933 0 1.693-.807 1.693-1.797c0-.989-.76-1.791-1.693-1.791z" />
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/in/ong-chong-yong/" className="fa fa-linkedin">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                </div>
            </div>
            <div className="contact-right">
                <form onSubmit={handleSubmit} className="contact-form">
                    <p>
                        My name/company is
                        <input data-click-text="name" style={{ width: `${nameWidth}px` }} name="from_name" placeholder={placeholderName} value={name}
                            onChange={e => setName(e.target.value)} />
                        and I would like to contact you regarding
                        <input data-click-text="service" style={{ width: `${serviceWidth}px` }} name="message" placeholder={placeholderService} value={service}
                            onChange={e => setService(e.target.value)} />
                        , You can get back to me at
                        <input data-click-text="email" style={{ width: `${emailWidth}px` }} type="email" name="email" placeholder="your email address" value={email}
                            onChange={e => setEmail(e.target.value)} />
                        to get things started.
                    </p>
                    <button type="submit">- send</button>
                </form>

            </div>
        </div>
    );
}



export default ContactPage;

import React from 'react';
import '../assets/styles/desktoponly.css';
import ModelViewer from '../components/ModelViewer';
import islandModel from '../assets/models/island.glb';

function DesktopOnlyPage() {

    return (
        <div className="container" style={{ display:"block" }}>
            <div className="top-panel">
                <div style={{ fontWeight: 'bold' }}>
                    this website is <br />
                    desktop only <br />
                    <span style={{ color: '#de5f5f' }}>for now</span>
                </div>
            </div>
            <div className="bottom-panel">
                <ModelViewer modelPath={islandModel} zoomLength = {0.8} position={[1,2,15]} />
            </div>
        </div>
    );
}

export default DesktopOnlyPage;

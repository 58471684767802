import React, { useState, useEffect, useRef } from 'react';
import '../assets/styles/main.css';
import ModelViewer from '../components/ModelViewer';
import whiteRoomModel from '../assets/models/whiteroom.glb';
import darkRoomModel from '../assets/models/blackroom.glb';
import useOnScreen from '../hooks/useOnScreen';
import { useTheme } from '../context/ThemeContext';

function Main() {
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);
    const [greeting, setGreeting] = useState('');
    const { theme } = useTheme();

    useEffect(() => {
        const hour = new Date().getHours();
        const month = new Date().getMonth();
        const day = new Date().getDate();

        if (month === 0 && day === 1) {
            setGreeting('happy new year 🎉');
        } else if (month === 11 && day === 25) {
            setGreeting('merry christmas 🎄');
        } else if (month === 9 && day === 31) {
            setGreeting('happy halloween 🎃');
        } else if (month === 6 && day === 4) {
            setGreeting('happy independence day 🇺🇸');
        } else if (month === 1 && day === 14) {
            setGreeting('happy valentine\'s day 💖');
        } else if (month === 11 && day === 31) {
            setGreeting('happy new year\'s eve 🎉');
        } else if (hour < 6) {
            setGreeting('good night 🌚');
        } else if (hour < 12) {
            setGreeting('good morning 🌞');
        } else if (hour < 18) {
            setGreeting('good afternoon ☀️');
        } else {
            setGreeting('good evening 🌙');
        }
    }, []);

    const modelPath = theme === 'light' ? whiteRoomModel : darkRoomModel;

    const greetingMessages = [
        "hope you're having a great day!",
        "let's get started.",
        "take a look around.",
        "feel free to reach out.",
        "thanks for stopping by!"
    ]

    const greetingMessage = greetingMessages[Math.floor(Math.random() * greetingMessages.length)];


    return (
        <div className="container" ref={ref}>
            <div className="text behind">
                <div style={{ fontWeight: 'bold' }} className={`${isVisible ? 'animated fadeIn' : ''}`}>
                    welcome to <br />
                </div>
            </div>
            <div className="model-viewer-wrapper">
                <ModelViewer modelPath={modelPath} zoomLength={0.55} position={[5, 0, -5]} controls={{ autoRotate: true }} />
                <div className="overlay-text">
                    👋 {greeting} <br />
                    {greetingMessage}
                </div>
            </div>
            <div className="text infront">
                <div style={{ fontWeight: 'bold' }} className={`${isVisible ? 'animated fadeIn' : ''}`}>
                    chong yong's <br />
                    portfolio. <br />
                </div>
            </div>
            <span className="scroll-btn">
                <span className="mouse">
                    <span>
                    </span>
                </span>
            </span>
        </div>
    );
}

export default Main;

import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useMove } from 'react-use-gesture';

const ParallaxBackground = ({ layers = [] }) => {  // Default to an empty array if not provided
    const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }));

    const bind = useMove(({ xy }) => {
        const x = xy[0] - window.innerWidth / 2;
        const y = xy[1] - window.innerHeight / 2;
        set({ xy: [x, y] });
    });

    return (
        <div {...bind()} className="parallax-container">
            {layers.map((layer, index) => (
                <animated.div
                    key={index}
                    style={{
                        transform: xy.to(
                            (x, y) => `translate(${x / (layer.factor * 5)}px, ${y / (layer.factor * 5)}px) scale(1.05)`
                        ),
                        backgroundImage: `url(${layer.image})`,
                        position: 'absolute',
                        inset: 0,
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        zIndex: (layer.zIndex),
                        overflow: 'visible',
                        
                    }}
                />
            ))}
        </div>
    );
};


export default ParallaxBackground;

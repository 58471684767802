import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ScrollableContainer from './components/ScrollableContainer';
import DesktopOnlyPage from './components/DesktopOnlyPage';
import ContactPage from './pages/contact';
import './index.css';
import TopNavBar from './components/TopNavBar';
import CustomCursor from './components/CustomCursor';
// import ReactGA from 'react-ga';

function App() {
  const [theme] = useState(localStorage.getItem('theme') || 'light');
  const location = useLocation(); // Now it's within the Router context.
  const [currentPage, setCurrentPage] = useState(0);

  // ReactGA.initialize('G-E34Y6XEX4V');

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, [location]);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setCurrentPage(0);
        break;
      case '/contact':
        setCurrentPage(1);
        break;
      default:
        setCurrentPage(null);
        break;
    }
  }, [location]);

  if (isMobile) {
    return (
      <Routes>
        <Route path="*" element={<Navigate replace to="/desktop-only" />} />
        <Route path="/desktop-only" element={<DesktopOnlyPage />} />
      </Routes>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <TopNavBar currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </header>
      <CustomCursor />
      <Routes>
        <Route path="/" element={<ScrollableContainer />} />
        <Route path="/contact" element={<ContactPage />} />
        {/* Additional routes can be added here */}
      </Routes>
    </div>
  );
}

export default App;
import React, { useEffect, useState } from 'react';
import '../assets/styles/customcursor.css';

const CustomCursor = () => {

    const [clickEffects, setClickEffects] = useState([]);

    const handleClick = event => {
        // Get the text from data-click-text attribute or fallback to a default text
        const text = event.target.dataset.clickText || "click!";

        const newEffect = {
            x: event.clientX,
            y: event.clientY,
            id: Math.random(), // Unique identifier for each effect
            text: text
        };
        setClickEffects(currentEffects => [...currentEffects, newEffect]);

        setTimeout(() => {
            setClickEffects(currentEffects => currentEffects.filter(effect => effect.id !== newEffect.id));
        }, 1000); // Duration until the effect disappears
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <>
            {clickEffects.map(effect => (
                <div
                    key={effect.id}
                    className="click-effect"
                    style={{ left: effect.x, top: effect.y }}
                >
                    {effect.text}
                </div>
            ))}
        </>
    );
};

export default CustomCursor;

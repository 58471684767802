import React, { useEffect } from 'react';
import { useTheme } from '../context/ThemeContext';
import '../assets/styles/themetoggle.css';

const ThemeToggle = () => {
    const { theme, setTheme } = useTheme();

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
        localStorage.setItem('theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    return (
        <label className="theme-switch">
            <input data-click-text="lightswitch" type="checkbox" onChange={toggleTheme} checked={theme === 'dark'} />
            <span  data-click-text="lightswitch" className="slider"></span>
        </label>
    );
};

export default ThemeToggle;

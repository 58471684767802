import React, { useState, useEffect, useMemo } from 'react';
import '../assets/styles/projectviewer.css'; // Ensure your CSS is correctly linked
import audioVis from '../assets/images/audiovis.png';
import yoozoointernship from '../assets/images/yoozoo.png';
import cgis from '../assets/images/cgis.png';
import lazbait from '../assets/images/lazbait.png';
import vibra from '../assets/images/vibra.png';

function ProjectViewer() {
    const [selectedProject, setSelectedProject] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    const projects = useMemo(() => [
        {
            id: 1,
            name: "Java 2D Gambling Swing Game",
            description: "Developed a 2D topdown game using Java Swing for a university project. The game featured a upgrade system, saving, and as you paly the game, you get to unlock more features. The game engine was developed from scratch. The game is open source and can be found on my github.",
            link: "https://github.com/ceewaigithub/addiction",
            imageUrl: "https://github.com/ceewaigithub/addiction/raw/main/res/images/titleScreen.png",
            openSource: true
        },
        {
            id: 2,
            name: "Waffles: Smart Driving Assistant",
            description: "This project is designed to enhance the driving experience by integrating traffic monitoring, music playback based on traffic conditions, and news reading functionalities into one platform. It's ideal for commuters looking to stay informed and entertained during their drives. waffles uses React for the frontend, Node.js for the backend, and Python for handling the core functionalities including traffic data interpretation, music selection, and news reading.            ",
            link: "https://github.com/ceewaigithub/waffles",
            imageUrl: audioVis,
            openSource: true
        },
        {
            id: 3,
            name: "Internship : YooZoo Traffic Project",
            description: "Worked as a backend developer intern at YooZoo Games, where I analyzed traffic data for a project spearheaded by LTA Singapore. The internship provided valuable experience in data analysis, visualization, and business intelligence.",
            link: "https://docs.google.com/presentation/d/1xqWBziBnT3k0tmOM3GaSfzCh59bmErQkxH1X3EjEwFE/edit?usp=sharing",
            imageUrl: yoozoointernship,
            openSource: false
        },
        {
            id: 4,
            name: "Project LazBait",
            description: "Developed a smart fish feeding platform using Computer Vision as well as a scheduling algorithm to feed fish in fish farms. The project was developed using Python, OpenCV, and TensorFlow. The project is not open source.",
            link: "https://docs.google.com/presentation/d/1tjlt-VDSbIAMGvI5aMaA53eYEGRxIp4Tk6ArRRcjGl0/edit?usp=sharing",
            imageUrl: lazbait,
            openSource: false
        },
        {
            id: 5,
            name: "Covid-19 Geospatial Analysis Project",
            description: "Used ArcGIS to analyze the impact of Covid-19 on schools in Singapore. The project involved mapping Covid-19 cases, vaccination rates, and school closures to identify hotspots and trends. The project is not open source.",
            link: "https://docs.google.com/presentation/d/182eE3dhKGU9lmWrA6TS09I5Qv0QSjqtbuTCDpH3GLok/edit?usp=sharing",
            imageUrl: cgis,
            openSource: false
        },
        {
            id : 6,
            name : "Maintenance as a service: Vibra",
            description : "Developed a predictive maintenance platform for industrial equipment using Machine Learning and IoT. The project involved developing a predictive maintenance model using Python and TensorFlow, and integrating it with an IoT device to monitor equipment health in real-time. The project is not open source.",
            link : "https://docs.google.com/presentation/d/1lej7f_lo68TTtIDhRN_M5ZJ-06qwzoOhQtx3W5KjHhg/edit?usp=sharing",
            imageUrl : vibra,
            openSource : false
        }
    ], []);

    useEffect(() => {
        // Setting initial project on component mount
        setSelectedProject(projects[0]);
        setTimeout(() => setIsVisible(true), 10);
    }, [projects]);

    useEffect(() => {
        if (selectedProject) {
            setIsVisible(false); // Fade out
            const timer = setTimeout(() => {
                setIsVisible(true); // Fade in
            }, 300); // This delay must match your CSS transition time
            return () => clearTimeout(timer);
        }
    }, [selectedProject]); // Re-run when selectedProject changes
    const selectProject = (project) => {
        if (!selectedProject || selectedProject.id !== project.id) {
            setSelectedProject(project);
        }
    };

    const handleScroll = (e) => {
        e.stopPropagation(); // Prevent scrolling of parent elements
    };

    return (
        <div className={`main-container ${!isVisible ? 'no-scroll' : ''}`}>
            <div className={`project-details ${isVisible ? 'active' : ''}`}>
                {selectedProject ? (
                    <>
                        <div className="project-open-source">
                            open source?
                            {selectedProject.openSource ? (
                                <span role="img" aria-label="Open Source" style={{ color: 'green' }}> 🟢</span>
                            ) : (
                                <span role="img" aria-label="Closed Source" style={{ color: 'red' }}> 🔴</span>
                            )}
                        </div>
                        <div className="project-title">
                            <h1>{selectedProject.name}</h1>
                        </div>
                        <div className="project-description">
                            <p>{selectedProject.description}</p>
                        </div>
                        <div className="project-link">
                            <a href={selectedProject.link} target="_blank" rel="noopener noreferrer">View Project</a>
                        </div>

                    </>
                ) : (
                    <p>Select a project to see the details</p>
                )}
            </div>
            <div className="project-list" onWheel={handleScroll}>

                <div className="grid-container">
                    {projects.map((project) => (
                        <div
                            key={project.id}
                            className={`project-item ${selectedProject && selectedProject.id === project.id ? 'selected' : ''}`}
                            onClick={() => selectProject(project)}
                            style={{
                                backgroundImage: `url(${project.imageUrl})`,
                                backgroundSize: 'cover',
                                filter: selectedProject && selectedProject.id === project.id ? 'none' : 'blur(4px)'
                            }}
                            data-click-text= {project.name}
                        >
                            <div data-click-text= {project.name} className="project-item-overlay"></div> {/* Separate overlay for darkening */}
                            <div data-click-text= {project.name} className="project-name-overlay">{project.name}</div>
                            {!project.imageUrl && <div className="project-metadata">Image not found</div>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProjectViewer;
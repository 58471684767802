import React, { Suspense, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Html, OrbitControls, Stage, useGLTF } from '@react-three/drei';
import '../assets/styles/modelviewer.css';

function ModelViewer({ modelPath, zoomLength = 0.45, position = [0, 0, 0], modelPosition = [0, 0, 0]}) {
    function disableScroll() {
        document.body.style.overflow = 'hidden';
    }

    function enableScroll() {
        document.body.style.overflow = 'auto';
    }
    return (
        <Canvas
            onMouseEnter={disableScroll} onMouseLeave={enableScroll}
            camera={{ fov: 35, zoom: zoomLength, position: position, near: 1, far: 50 }}
            style={{ background: 'transparent' }}
        >
            <Suspense fallback={<LoadingIndicator />}>
                <Stage environment="city" intensity={0.1} preset="rembrandt" contactShadow={false}>
                    <Model path={modelPath} position={modelPosition} />
                </Stage>
            </Suspense>
            <OrbitControls
                enablePan={false}
                enableZoom={false}
                enableRotate={true}
                zoomSpeed={0.3}
            />
        </Canvas>
    );
}

function Model({ path }) {
    const { scene } = useGLTF(path);
    const modelRef = useRef();

    useFrame(() => {
        modelRef.current.rotation.y -= 0.00025;
    });

    return <primitive object={scene} scale={0.1} ref={modelRef} />;
}

function LoadingIndicator() {
    return (
        <Html center>
            <div className="loading-container">
                <div className="spinner"></div>
            </div>
        </Html>
    );
}

export default ModelViewer;
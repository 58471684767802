import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import ThemeToggle from './ThemeToggle';
import '../assets/styles/nav.css';

function TopNavBar({ currentPage, setCurrentPage }) {
    return (
        <nav className='topnav'>
            <ul>
                <li>
                    <Link to="/" data-click-text="home" onClick={() => setCurrentPage(0)} className={currentPage === 0 ? 'active' : ''}>
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="/contact" data-click-text="contact" onClick={() => setCurrentPage(1)} className={currentPage === 1 ? 'active' : ''}>
                        Contact
                    </Link>
                </li>
            </ul>
            <div className="theme-toggle-container">
                <ThemeToggle />
            </div>
        </nav>
    );
}

export default TopNavBar;

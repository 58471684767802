import React from 'react';
import classNames from 'classnames';
import '../assets/styles/sidenav.css';

function SideNavBar({ currentSection, scrollToSection }) {
    return (
        <nav className="sidenav">
            <ul>
                <li>
                    <button data-click-text="main" onClick={() => scrollToSection(0)}>
                        🏠 <span className={classNames('menu-dot', { active: currentSection === 0 })}></span>
                    </button>
                </li>
                <li>
                    <button data-click-text="roles" onClick={() => scrollToSection(1)}>
                        📝 <span className={classNames('menu-dot', { active: currentSection === 1 })}></span>
                    </button>
                </li>
                <li>
                    <button data-click-text="projects" onClick={() => scrollToSection(2)}>
                        🔧 <span className={classNames('menu-dot', { active: currentSection === 2 })}></span>
                    </button>
                </li>
                <li>
                    <button data-click-text="bio" onClick={() => scrollToSection(3)}>
                        👤 <span className={classNames('menu-dot', { active: currentSection === 3 })}></span>
                    </button>
                </li>
            </ul>
        </nav>
    );
}

export default SideNavBar;

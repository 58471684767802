import { useState, useEffect } from 'react';

function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            { threshold: 0.5 } // Triggers when 50% of the element is in view
        );

        const currentRef = ref.current; // Create a variable and assign ref.current to it

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref]);

    return isIntersecting;
}

export default useOnScreen;

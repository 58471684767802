import React from "react";
import '../assets/styles/scrollContainer.css';
import ReactPageScroller, { SectionContainer } from 'react-page-scroller';
import Bio from '../pages/bio';
import Main from '../pages/main';
import AboutMe from '../pages/aboutme';
import SideNavBar from './SideNavBar';
import ProjectViewer from "./ProjectViewer";

export default class ScrollableContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSection: 0,
            scrollPosition: 0,
            loading: true // Initially true until content is loaded
        };
    }

    handlePageChange = (number, scrollPosition) => {
        this.setState({ currentSection: number, scrollPosition });
    };

    handleBeforePageChange = (number, scrollPosition) => {
        // Optional: Handle anything before page changes
    };

    scrollToPage = pageNumber => {
        this.setState({ currentSection: pageNumber });
    };

    render() {

        return (
            <React.Fragment>
                <SideNavBar currentSection={this.state.currentSection} scrollToSection={this.scrollToPage} />
                <ReactPageScroller
                    pageOnChange={this.handlePageChange}
                    onBeforePageScroll={this.handleBeforePageChange}
                    customPageNumber={this.state.currentSection}
                >
                    <SectionContainer>
                        <Main />
                    </SectionContainer>
                    <SectionContainer>
                        <Bio />
                    </SectionContainer>
                    <SectionContainer>
                        <ProjectViewer />
                    </SectionContainer>
                    <SectionContainer>
                        <AboutMe />
                    </SectionContainer>
                </ReactPageScroller>
            </React.Fragment>
        );
    }
}

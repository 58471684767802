import '../assets/styles/aboutme.css';
import React from 'react';
import ParallaxBackground from '../components/ParallaxBackground';
import two from '../assets/images/2.png';
import three from '../assets/images/3.png';
import four from '../assets/images/4.png';
import five from '../assets/images/5.png';
import six from '../assets/images/6.png';


function AboutMe() {
    const layers = [
        { image: five, factor: 50, zIndex: 1 },
        { image: four, factor: 20, zIndex: 1 },
        { image: three, factor: 15, zIndex: 5 },
        { image: two, factor: 10, zIndex: 5 },
        { image: six, factor: 5, zIndex: 5 },
    ];

    return (
        <div id="background-page">
            <ParallaxBackground layers={layers} />
            <div className="cardProfile">
                <div className="half half-left">
                    <div className="card">
                        <div className="border">
                            <h2>Ong Chong Yong</h2>
                        </div>
                    </div>
                </div>
                <div className="half half-right">
                    <h3 className="bio">get to know me.</h3>
                    <p>
                        👋 Hey there, hope you liked my portfolio! <br />
                        I'm Chong Yong, currently delving into the realms of technology while pursuing my Computer 
                        Science degree at Singapore Management University. 🎓 <br/>
                        When it comes to tech, I'm all about discovering how it can enrich our daily lives. 
                        I thrive on diving into side projects to broaden my understanding. 🛠️ <br/>
                        Apart from hitting the books and coding, you'll often find me enjoying a cup of coffee ☕, 
                        immersed in a captivating book, or staying updated on the latest tech trends. <br />
                        I'm always eager to embrace new adventures and share my learnings along the journey.
                        Let's connect and collaborate on something extraordinary!
                    </p>
                    <hr style={{ width: "10%" }} />
                    <p className='opentowork'>I'm currently <b>🟢 open</b> to work!</p>

                </div>
            </div>

        </div>
    );
}

export default AboutMe;
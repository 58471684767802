import React, { useState, useEffect, useRef, useMemo } from 'react';
import '../assets/styles/bio.css';
import ModelViewer from '../components/ModelViewer';
import ceewaiModel from '../assets/models/ceewai.glb';
import islandToriModel from '../assets/models/islandtori.glb';
import useOnScreen from '../hooks/useOnScreen';

function Bio() {
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);
    const titles = useMemo(() => ["developer", "engineer", "architect", "enthusiast", "nerd"], []);
    const [index, setIndex] = useState(0);
    const [displayedTitle, setDisplayedTitle] = useState(titles[0]); // Initialize with the first title
    const [isTyping, setIsTyping] = useState(false);
    const [chosenModel] = useState(Math.random() < 0.5 ? ceewaiModel : islandToriModel); // Choose model on initial render

    useEffect(() => {
        let currentTitle = titles[index];
        let charIndex = 0;
        setIsTyping(true);

        const typeChar = () => {
            setDisplayedTitle(prev => currentTitle.slice(0, charIndex));
            charIndex++;
            if (charIndex <= currentTitle.length) {
                setTimeout(typeChar, 150); // Speed of typing each character
            } else {
                setIsTyping(false); // Complete the typing
            }
        };

        const typeTimeout = setTimeout(typeChar, 500); // Delay before typing starts

        return () => clearTimeout(typeTimeout);
    }, [index, titles, titles.length]); // React on index change, titles change, and titles.length change

    useEffect(() => {
        if (!isTyping) {
            const intervalId = setInterval(() => {
                setIndex(prevIndex => (prevIndex + 1) % titles.length);
            }, 1500); // Change title every 1.5 seconds

            return () => clearInterval(intervalId);
        }
    }, [isTyping, titles, titles.length]); // React to changes in isTyping, titles, and titles.length

    return (
        <div className="container-bio">
            <div className="left-panel-bio">
                <ModelViewer modelPath={chosenModel} zoomLength={0.55} />
            </div>
            <div className="right-panel-bio" ref={ref}>
                <section className={`section section--intro ${isVisible ? 'animated fadeIn' : ''}`} id="intro">
                    <div className="section__inner">
                        <div className="block">
                            <h1 id="loudHeader">chong yong is a<br />
                                software 💻 <br />
                                <span data-click-text={displayedTitle} className='primary-text'>{displayedTitle}_</span><br />  {/* Always show cursor */}
                            </h1>
                            <p className="lead">
                                here are some projects i've worked on:
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Bio;
